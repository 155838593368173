import {forwardRef, useImperativeHandle, useRef} from 'react';
import {useLink} from '@react-aria/link';
import {twMerge} from 'tailwind-merge';

import type {AriaLinkOptions} from '@react-aria/link';
import type {ReactNode} from 'react';
import {anchorStyles} from './styles';
import type {DynamicComponentProps} from '../../types';
import type {AnchorVariantProps} from './styles';

export type AnchorProps = Omit<AriaLinkOptions, 'isDisabled' | 'as'> &
	Omit<DynamicComponentProps, 'prefix'> &
	AnchorVariantProps & {
		disabled?: boolean;
		prefix?: ReactNode;
		suffix?: ReactNode;
	};
const Anchor = forwardRef<HTMLElement, AnchorProps>(
	(
		{as: Component = 'a', prefix, suffix, children, disabled, className, href, rel, target, ...props},
		forwardedRef,
	) => {
		const ref = useRef<HTMLElement>(null);
		const {linkProps} = useLink(
			{...props, elementType: Component as string, isDisabled: disabled, href, rel, target},
			ref,
		);

		useImperativeHandle(forwardedRef, () => ref.current as HTMLElement);

		return (
			<Component
				{...linkProps}
				className={twMerge(anchorStyles({disabled}), className)}
				disabled={disabled}
				href={href}
				ref={ref}
				rel={rel}
				target={target}
			>
				{prefix}
				{children}
				{suffix}
			</Component>
		);
	},
);

Anchor.displayName = 'Anchor';

export default Anchor;

export * from './styles';
