import {forwardRef} from 'react';
import {IconX} from '@tabler/icons-react';

import IconButton from '../icon-button';
import type {IconButtonProps} from '../icon-button';

export type CloseButtonProps = Omit<IconButtonProps, 'children' | 'loading' | 'icon'>;
const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>((props, forwardedRef) => (
	<IconButton {...props} aria-label="Close" icon={IconX} intent="ghost" ref={forwardedRef} />
));

CloseButton.displayName = 'CloseButton';

export default CloseButton;
