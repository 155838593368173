import {forwardRef} from 'react';
import {twMerge} from 'tailwind-merge';

import type {PropsWithChildren} from 'react';
import Button from '../button';
import {textButtonStyles} from './styles';
import type {ButtonProps} from '../button';
import type {TextButtonVariantProps} from './styles';

export type TextButtonProps = PropsWithChildren<Omit<ButtonProps, 'intent'> & TextButtonVariantProps>;

const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>(
	({size, color, className, ...props}, forwardedRef) => (
		<Button
			{...props}
			className={twMerge(textButtonStyles({size, color}), className)}
			color={color}
			intent="ghost"
			ref={forwardedRef}
			size={size}
		/>
	),
);

TextButton.displayName = 'TextButton';

export default TextButton;

export * from './styles';
