import {IconLoader2} from '@tabler/icons-react';
import {m} from 'framer-motion';
import {twMerge} from 'tailwind-merge';

import type {ComponentProps} from 'react';
import {loadingSpinnerStyles} from './styles';
import type {LoadingSpinnerVariantProps} from './styles';

export type LoadingSpinnerProps = LoadingSpinnerVariantProps & ComponentProps<typeof IconLoader2>;
const LoadingSpinner = ({size, className, ...props}: LoadingSpinnerProps) => (
	<div className={twMerge('w-fit', className)}>
		<m.div
			animate={{opacity: 1, transition: {ease: 'easeOut', duration: 0.15}}}
			className="animate-spin"
			initial={{opacity: 0}}
		>
			<IconLoader2 {...props} className={loadingSpinnerStyles({size})} />
		</m.div>
	</div>
);

LoadingSpinner.displayName = 'LoadingSpinner';

export default LoadingSpinner;

export * from './styles';
