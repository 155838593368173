import {forwardRef, useMemo} from 'react';
import {mergeProps} from '@react-aria/utils';
import {m} from 'framer-motion';
import {twMerge} from 'tailwind-merge';

import type {MotionProps} from 'framer-motion';
import {paperStyles} from './styles';
import type {DynamicComponentProps} from '../../types';
import type {PaperVariantProps} from './styles';

export type PaperProps = PaperVariantProps &
	DynamicComponentProps & {
		enableMotion?: boolean;
		motionOverrides?: MotionProps;
	};

const Paper = forwardRef<HTMLElement, PaperProps>(
	(
		{
			as: Component = 'div',
			padding,
			shadow,
			bordered,
			accent,
			inverted,
			accentPosition,
			enableMotion,
			motionOverrides,
			className,
			...props
		},
		forwardedRef,
	) => {
		const MotionComponent = useMemo(
			() => (enableMotion && typeof Component === 'string' ? m.create(Component) : Component),
			[enableMotion, Component],
		);

		const motionProps = useMemo<MotionProps>(
			() =>
				enableMotion
					? {
							animate: {opacity: 1, transition: {ease: 'easeOut', duration: 0.15}},
							exit: {opacity: 0, transition: {ease: 'easeOut', duration: 0.15}},
							initial: {opacity: 0},
						}
					: {},
			[enableMotion],
		);

		return (
			<MotionComponent
				{...mergeProps(props, motionProps, motionOverrides)}
				className={twMerge(
					paperStyles({padding, shadow, bordered, accent, accentPosition, inverted}),
					className,
				)}
				ref={forwardedRef}
			/>
		);
	},
);

Paper.displayName = 'Paper';

export default Paper;

export * from './styles';
